<template>
    <div v-touch:swipe.right="showTerminalHandler" id="app">

        <div class="uk-position-relative uk-visible-toggle slider-struct" tabindex="-1"
             :id="slideshowIdTransfer"
             uk-slideshow="ratio: false; autoplay: true; autoplay-interval: 10000; draggable: false; animation: push">

            <ul v-if="isTransferPage" class="uk-slideshow-items uk-slideshow-items-view" uk-height-viewport>
                <li v-for="(picture, key) in getTransferBackgroundPictures" :key="key">
                    <video v-if="picture && isVideo(picture.id)"
                           muted
                           oncontextmenu="return false;"
                           playsinline
                           uk-cover>
                        <source :src="(picture.isExternal ? 'https://update.phy-prints.com/swifter/' : apiUrl+'/transfer-screensavers/')+picture.id"
                                :type="'video/'+getVideoType(picture.id)">
                    </video>
                    <img v-else-if="picture" :src="(picture.isExternal ? 'https://update.phy-prints.com/swifter/' : apiUrl+'/transfer-screensavers/')+picture.id" class="uk-background-cover" uk-cover>
                </li>
            </ul>
            <ul v-else-if="isGalleryPage" class="uk-slideshow-items" uk-height-viewport>
                <li>
                    <div class="gallery-background uk-background-cover" uk-cover/>
                </li>
            </ul>
            <ul v-else class="uk-slideshow-items uk-slideshow-items-view" uk-height-viewport>
                <li v-for="(picture, key) in getBackgroundPictures" :key="key">
                    <img v-if="picture" :src="apiUrl+'/pictures/'+picture" class="uk-background-cover" uk-cover>
                </li>
            </ul>

            <div class="uk-position-top scrollable uk-height-1-1">

                <div v-if="false && getInfos && getInfos.showHint" class="show-hint-struct">
                    <div class="uk-card show-hint uk-text-center uk-position-z-index" uk-sticky>
                        DEV Version
                    </div>
                </div>

                <PWAPrompt class="pwa-prompt-struct" v-if="false && getInfos && getInfos.name" :name="getInfos.name"/>

                <div :class="'content '+ (isGalleryPage ? 'gallery' :'')">

                    <div v-if="!isGalleryPage && getInfos.logo" class="uk-padding logo-struct"
                         :style="'text-align:'+getInfos.logoPosition+'!important;'">
                        <LinkObject to="">
                            <img :src="apiUrl+'/logos/'+getInfos.logo" class="logo"/>
                        </LinkObject>
                    </div>
                    <div v-else-if="!isGalleryPage&& !isTransferPage" class="empty-logo-struct"/>

                    <Card v-touch:touchhold="showTerminalHandler"
                          v-if="!isGalleryPage && !isAdminPage&& !isTransferPage"
                          class="title-struct uk-text-center">
                        <LinkObject to="">
                            <span v-if="loadingInfos">
                                <div uk-spinner/>
                            </span>
                            <span v-else-if="getInfos.name">
                                {{ getInfos.name }}
                            </span>
                            <span v-else class="uk-invisible">
                                Name
                            </span>
                        </LinkObject>
                    </Card>

                    <div
                        v-if="getInfos.absence && isDateStringPast(getAbsenceStart(getInfos.absence.start)) && isDateStringFuture(getInfos.absence.end)">
                        <Card is-dark mode="default" class="absence uk-text-center">

                            <span v-html="
                                $t('absence.message', {
                                    start: getFormattedDateByDateString(getInfos.absence.start),
                                    end: getFormattedDateByDateString(getInfos.absence.end),
                                })
                            "/>

                        </Card>
                    </div>

                    <LoadingStruct :is-loading="loadingInfos" is-dark is-spinner-hidden>
                        <div :class="'content-struct '+ (isGalleryPage || isTransferPage ? 'gallery' :'')+ (isAdminPage ? 'admin' :'')">
                            <router-view/>
                        </div>

                        <div class="footer">
                            <div
                                class="uk-animation-fade uk-margin-medium-top uk-margin-medium-right uk-margin-medium-bottom">
                                <div uk-grid
                                     class="uk-grid-small uk-child-width-auto uk-flex-right uk-flex-middle">

                                    <div v-if="getInfos.footerText">
                                        {{ getInfos.footerText }}
                                    </div>

                                    <div>
                                        <ToggleItem toggle="target: #termsAndConditions">
                                            {{ $t('termsAndConditions') }}
                                        </ToggleItem>
                                        <Modal id="termsAndConditions" :title="$t('termsAndConditions')" is-extended>
                                            <div v-html="getInfos.termsAndConditions"/>
                                        </Modal>
                                    </div>
                                    <div>
                                        <ToggleItem toggle="target: #dataPrivacy">
                                            {{ $t('dataPrivacy') }}
                                        </ToggleItem>
                                        <Modal id="dataPrivacy" :title="$t('dataPrivacy')" is-extended>
                                            <div v-html="getInfos.dataPrivacy"/>
                                        </Modal>
                                    </div>
                                    <div>
                                        <ToggleItem toggle="target: #siteNotice">
                                            {{ $t('siteNotice') }}
                                        </ToggleItem>
                                        <Modal id="siteNotice" :title="$t('siteNotice')" is-extended>
                                            <div v-html="getInfos.siteNotice"/>
                                        </Modal>
                                    </div>

                                    <div>
                                        <LanguageSelector id="main"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PWAHint class="pwa-hint-struct" v-if="false && getInfos && getInfos.name"
                                 :name="getInfos.name"/>

                        <TerminalControlForm :modal-id="modalIdTerminalControl"/>

                    </LoadingStruct>

                </div>
            </div>
        </div>

        <CookieControl v-if="!loadingInfos"/>

    </div>
</template>

<script>

import VueCookie from "vue-cookie";
import Vue from "vue";
import {i18n} from "@/main";
import axios from "axios";
import UIkit from "uikit";
import CookieControl from "@/components/CookieControl";
import Card from "@/components/generic/Card";
import LinkObject from "@/components/LinkObject";
import ToggleItem from "@/components/generic/ToggleItem";
import LanguageSelector from "@/components/LanguageSelector";
import Modal from "@/components/generic/Modal";
import LoadingStruct from "@/views/LoadingStruct";
import dayjs from "dayjs";
import {Validator} from "vee-validate";
import PWAPrompt from "@/components/PWAPrompt.vue";
import PWAHint from "@/components/PWAHint.vue";
import TerminalControlForm from "@/components/forms/TerminalControlForm.vue";

Vue.mixin({
    computed: {
        isGalleryPage() {
            if (this.getMode === 'gallery') {
                return true;
            }

            return this.$route.path.includes('galler');
        },
        isTransferPage() {
            return this.$route.path.includes('transfer');
        },
        isAdminPage() {
            return this.$route.path.includes('admin');
        },
        getMode() {
            return this.$store.state.mode;
        },
        getToken() {
            return this.$store.state.token;
        },
        isTerminalConnectorRunning() {
            return this.$store.state.terminalConnectorRunning;
        },
        isStart() {
            return this.$store.state.start;
        },
        isAttemptedConnectorRegister() {
            return this.$store.state.attemptedConnectorRegister;
        },
        isTerminalMode() {
            const mode = Vue.localStorage.get('mode')
            if (mode !== "terminal") {
                return false;
            }

            if (!this.getInfos.terminal) {
                return false;
            }

            return true;
        },
        isTerminalWithAds() {
            if (!this.isTerminalMode) {
                return false;
            }

            if (!this.getInfos.terminal.hasAds) {
                return false;
            }

            return true;
        },
        getInfos() {
            return this.$store.state.infos;
        },
        fileExtensions() {
            const extensions = this.$store.state.infos.allowedFileExtensions;

            const lower = extensions.map(element => {
                return element.toLowerCase();
            });

            return [...new Set(lower)];
        },
        getMaxFileSizeInMB() {
            return this.$store.state.infos.maxFileSizeInMB;
        },
    },
    methods: {
        transferScreensaverControl() {
            let self= this;
            const slideshow = UIkit.slideshow("#" + this.slideshowIdTransfer);
            const slideshowElement = document.getElementById(this.slideshowIdTransfer);
            slideshowElement.addEventListener('itemshow', (event) => {
                if(!self.isTransferPage){
                    return
                }

                const element = event.target.children[0].children[0];
                const currentIndex = event.detail[0].index

                let nextIndex = currentIndex + 1;
                if (self.getTransferBackgroundPictures.length > nextIndex) {
                    nextIndex = 0;
                }

                if (element && element.tagName === 'VIDEO') {
                    slideshow.stopAutoplay();

                    element.addEventListener('ended', () => {
                        slideshow.show(nextIndex);
                        slideshow.startAutoplay();
                    });
                }
            });
        },
        generateRandomNumber(length) {
            // eslint-disable-next-line no-unused-vars
            let gen = n => [...Array(n)].map(_ => Math.random() * 10 | 0).join``;
            return (1 + Math.random() * 9 | 0) + gen(length - 1);
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
        },
        getParsedBytes(sizeInBytes) {
            return (this.$options.filters.prettyBytes(sizeInBytes)).replace(".", ",");
        },
        globalDispatchEvent(event) {
            window.dispatchEvent(event);
        },
        generatedTitle(title) {
            const parted = title.split(" ");
            if (parted.length === 2) {
                return parted[0][0] + parted[1][0];
            }

            return parted[0][0]
        },
        getTerminalConnectorRunning() {
            if (this.isTerminalMode || this.isAttemptedConnectorRegister) {

                let http = axios.create();
                http.get('http://localhost:63178/status').then(() => {
                    this.$store.state.terminalConnectorRunning = true;

                }).catch(() => {
                    this.$store.state.terminalConnectorRunning = false;

                });
            }
        },
        getTabStatus(key, tab) {
            let hash = this.$route.hash;
            if (hash) {
                if (hash.substring(1) === tab) {
                    return 'uk-active';
                }

                return '';
            }

            if (key === 0) {
                return 'uk-active';
            }

            return '';
        },
        setHashtag(tab) {
            history.pushState({}, null, "#" + tab);
        },
        isVideo(item) {
            if (item.name) {
                return item.name.endsWith(".mp4") || item.name.endsWith(".webm");
            }

            if (item) {
                return item.endsWith(".mp4") || item.endsWith(".webm");
            }

            return false;
        },
        getVideoType(item) {
            if (item.name) {
                if (item.name.endsWith(".mp4")) {
                    return "mp4";
                }

                if (item.name.endsWith(".webm")) {
                    return "webm";
                }
            }

            if (item) {
                if (item.endsWith(".mp4")) {
                    return "mp4";
                }

                if (item.endsWith(".webm")) {
                    return "webm";
                }
            }

            return "mp4";
        },
        showTerminalHandler() {
            if (!this.getInfos || !this.getInfos.terminal) {
                return;
            }

            const mode = Vue.localStorage.get('mode')
            if (mode !== "terminal") {
                return;
            }

            UIkit.modal("#" + this.modalIdTerminalControl).show();
        },
        formatMeasurement(value) {
            return value.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1});
        },
        getPixelFromMeasurement(value, dpi) {
            let measurementFactor = 1.0
            if (this.getInfos.measurementUnit === "cm") {
                measurementFactor = 2.54;
            }

            return value * dpi / measurementFactor;
        },
        isPictureTooLength(item) {
            const article = this.getArticleByID(item.articleID);

            if (!article.isHeightVariable) {
                return false;
            }

            if (!article.maxLength) {
                return false;
            }

            return item.measurementValue > article.maxLength;
        },
        roundWidth(width) {
            const integerPart = parseInt(width);
            const decimalPart = width - integerPart;

            if (decimalPart >= 0.6) {
                return Math.ceil(width);
            }

            return Math.floor(width);
        },
        roundHeight(height) {
            const integerPart = parseInt(height);
            const decimalPart = height - integerPart;

            if (decimalPart >= 0.6) {
                return Math.ceil(height);
            }

            return Math.floor(height);
        },
        calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
            let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            return [srcWidth * ratio, srcHeight * ratio, ratio];
        },
        getOriginDimensions(coordinates, ratio) {
            return {
                width: coordinates.width / ratio,
                height: coordinates.height / ratio,
                left: coordinates.left / ratio,
                top: coordinates.top / ratio,
            };
        },
        isFilenameValid(name) {
            let fileNameInvalidChars = [":", "*", "/", '\\', "?", '"', ">", "<", "|"];

            for (let i = 0, j = fileNameInvalidChars.length; i < j; i++) {
                if (name.includes(fileNameInvalidChars[i])) {
                    return false
                }
            }

            return true
        },
        getCountryByLang(lang) {
            // let lang = this.$router.currentRoute.params.lang;

            if (lang === "ch") {
                return "switzerland";
            }

            if (lang === "at") {
                return "austria";
            }

            if (lang === "li") {
                return "liechtenstein";
            }

            if (lang === "en") {
                return "unitedKingdom";
            }

            if (lang === "it") {
                return "italy";
            }

            if (lang === "fr") {
                return "france";
            }

            if (lang === "nl") {
                return "netherlands";
            }

            if (lang === "fi") {
                return "finland";
            }

            return "germany";
        },
        getIconByName(method) {
            if (method === "terminalConnector") {
                return "cog";
            }
            if (method === "remoteAccess") {
                return "desktop";
            }
            if (method === "update") {
                return "download";
            }
            if (method === "signOut") {
                return "sign-out";
            }

            if (method === "restart") {
                return "refresh";
            }
            if (method === "shutdown") {
                return "close";
            }

            if (method === "smartphone") {
                return "phone";
            }

            if (method === "microSdCard") {
                return "folder";
            }

            if (method === "cfCard") {
                return "folder";
            }

            if (method === "xdCard") {
                return "folder";
            }

            if (method === "hcCard") {
                return "folder";
            }

            if (method === "cdRom") {
                return "folder";
            }

            if (method === "usbStick") {
                return "usb";
            }

            if (method === "sdCard") {
                return "folder";
            }

            if (method === "telephone") {
                return "receiver";
            }

            if (method === "shop") {
                return "cart";
            }

            if (method === "invoice") {
                return " file-text";
            }

            if (method === "pickup") {
                return "bag";
            }

            if (method === "shipping") {
                return "mail";
            }

            if (method === "address") {
                return "home";
            }

            return "cart";
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getPaperName(paper) {
            if (['matt', 'glossy'].includes(paper)) {
                return this.$t(paper);
            }

            return paper;
        },
        getTargetLang() {
            let lang = navigator.language;
            if (!lang) {
                return "de";
            }

            lang = lang.split("-");
            if (!lang || !lang[0]) {
                return "de";
            }

            return lang[0];
        },
        getNumberFormatted(number) {
            return new Intl.NumberFormat(this.getTargetLang(), {minimumFractionDigits: 1}).format(number);
        },
        getNumberWithCurrency(number) {
            return new Intl.NumberFormat(this.getTargetLang(), {
                style: 'currency',
                currency: this.getInfos.currency
            }).format(number);
        },
        isSwitchDecimal() {
            const exampleNumber = this.getNumberFormatted(1) + "";

            if (exampleNumber.includes(".")) {
                return true
            }

            return false;
        },
        getFormattedTypesString(types) {
            let formatted = "";

            if (types.length === 1) {
                formatted += types[0];
                return formatted;
            }

            for (let i = 0, j = types.length; i < j; i++) {
                if (i === (types.length - 1)) {
                    formatted += " " + this.$t('or') + " ";
                }

                formatted += types[i];

                if (i < types.length - 2) {
                    formatted += ", ";
                }
            }

            return formatted;
        },
        receiveInfos() {
            let http = axios.create();
            http.get(this.apiUrl + '/infos').then(response => {
                this.$store.state.infos = response.data;
                this.loadingInfos = false;

            }).catch(e => {
                this.checkGenericError(e);
                this.loadingInfos = false;

            });
        },
        getLocalFileUrl(file) {
            return URL.createObjectURL(file);
        },
        cloneObject(object) {
            return JSON.parse(JSON.stringify(object));
        },
        reload() {
            location.reload();
        },
        getRouteParts(properties) {
            let pathParts = this.$route.path.split('/');
            let breadCrums = [];
            let skipItem = false;
            let nameSet = false;
            for (let i = 2, j = pathParts.length; i < j; i++) {

                let route = pathParts[i];
                if (i > 2) {
                    route = "";
                    for (let k = 2, l = i + 1; k < l; k++) {
                        route += pathParts[k] + "/";
                    }
                }

                let name = pathParts[i];
                let disabled = false;

                let tooltip = null;

                if (properties) {
                    for (let k = 0, l = properties.length; k < l; k++) {

                        if (properties[k] && properties[k].key === i - 2) {

                            if (properties[k].tooltip) {
                                tooltip = properties[k].tooltip;
                            }

                            if (properties[k].name) {
                                name = properties[k].name;
                                nameSet = true;
                            }

                            if (properties[k].disabled) {
                                disabled = properties[k].disabled;
                            }

                            if (properties[k].hidden) {
                                skipItem = true;
                                continue;
                            }

                        }

                        if (!nameSet) {
                            name = this.$t(pathParts[i]);
                        }

                    }

                    if (skipItem) {
                        skipItem = false;
                        continue;
                    }

                } else {
                    name = this.$t(pathParts[i]);

                }

                route.trim();
                if (route.charAt(route.length - 1) === "/") {
                    route = route.slice(0, -1);
                }

                breadCrums.push({
                    name: name.trim(),
                    route: route,
                    disabled: disabled,
                    tooltip: tooltip,
                    active: false,
                })

            }

            breadCrums[breadCrums.length - 1].active = true;

            return breadCrums;
        },
        getDaysAgoByDateString(genericDate) {
            let now = new Date();
            let date = new Date(genericDate);

            now.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);

            return parseInt((now.getTime() - date.getTime()) / (1000 * 3600 * 24));
        },
        getSymbolByCurrency(currency) {
            if (!currency || currency === "") {
                return "€";
            }

            let format = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: currency
            }).format(0);

            let split = format.split(" ");
            return split[1];
        },
        switchLanguage(language) {
            if (this.$i18n.locale === language) {
                Vue.localStorage.set('language', language)
                if (origin.includes("localhost")) {
                    Vue.localStorage.set('vue-lang', language)
                }
                return
            }

            let currentRoute = this.$router.currentRoute.path
            let languages = this.languages

            if (!languages.includes(language)) {
                language = 'de';
            }

            for (let i = 0, j = languages.length; i < j; i++) {
                if (languages[i] !== language) {
                    currentRoute = currentRoute.replace(languages[i], language)
                }
            }

            this.$i18n.locale = language
            Vue.localStorage.set('language', language)

            if (origin.includes("localhost")) {
                Vue.localStorage.set('vue-lang', language)
            }

            if (currentRoute !== this.$router.currentRoute.path) {
                this.$router.push(currentRoute)
            }

            dayjs.locale(language);
            Validator.localize(language);
        },
        routeTo(to) {
            const path = "/" + i18n.locale + '/' + to;
            if (this.$route.path === path) {
                return;
            }

            this.$router.push(path);
        },
        getFormattedDateByDateString(genericDate) {
            let dateLang = this.$i18n.locale + "-" + this.$i18n.locale;

            return dayjs(genericDate).locale(dateLang).format("LL");
        },
        getFormattedDateTimeByDateString(genericDate, slim = false) {
            let dateLang = this.$i18n.locale + "-" + this.$i18n.locale;

            return dayjs(genericDate).locale(dateLang).format("LLL" + (slim ? '' : 'L'));
        },
        getDaysLeftByDateString(genericDate, withoutSuffix = true) {
            return dayjs(genericDate).fromNow(withoutSuffix)
        },
        isDateStringFuture(genericDate) {
            return dayjs(genericDate).isAfter(dayjs());
        },
        isDateStringPast(genericDate) {
            return dayjs(genericDate).isBefore(dayjs());
        },
        checkGenericError(e) {
            this.$store.state.requestedPath = this.$route.path;
            if (e && e.response && e.response.data) {
                if (e.response.status === 408 || e.response.data.response === 'Your token is invalid, request a new one.') {
                    localStorage.setItem('session_invalid', "true");
                    this.reload();

                } else if (e.response.data.response === 'Your token is invalid, please renew your authentication token.' || e.response.data.response === 'Your token has expired, please renew your authentication token.') {
                    this.$store.state.token = null;
                    VueCookie.delete('token')
                    this.routeTo("login");

                }
            }

            console.log(e);
        },
        logout(notify = true) {
            this.$cookie.delete("token");
            this.$store.state.token = null;

            if (notify) {
                UIkit.notification({
                    message: this.$t('logout.success'),
                    status: 'info',
                    pos: 'top-center',
                    timeout: this.notificationTimeout,
                });
            }

            this.routeTo('login');
        },
    }
})

export default {
    name: 'App',
    components: {
        TerminalControlForm,
        PWAHint,
        PWAPrompt,
        LoadingStruct, Modal, LanguageSelector, ToggleItem, LinkObject, Card, CookieControl
    },
    data() {
        return {
            loadingInfos: true,
            appHeight: 0,

            modalIdTerminalControl: "modal-terminal-control",

            slideshowIdTransfer: "slide-show-transfer",
        }
    },
    created() {
        if (location.hostname !== "localhost" && location.protocol !== "https:") {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }

        axios.defaults.timeout = this.apiTimeout;

        if (origin.includes("localhost")) {
            this.$store.state.baseUrl = "http://localhost:8080";
        }

        let token = VueCookie.get("token");
        if (token) {
            this.$store.state.token = token;
            axios.defaults.headers.common["token"] = token;
        }

        this.receiveInfos();
    },
    methods: {
        getAbsenceStart(date) {
            return dayjs(date).subtract(3, 'day');
        },
    },
    computed: {
        getBackgroundPictures() {
            return this.getInfos.pictures;
        },
        getTransferBackgroundPictures() {
            return this.getInfos.transfer.fixedScreensavers;
        },
    },
    mounted() {
        /*
        let self = this;
        setInterval(function () {
            self.appHeight = window.innerHeight;
        }, 50);
         */


            this.transferScreensaverControl();


        const lang = Vue.localStorage.get('language');

        dayjs.locale(lang);
        Validator.localize(lang);

        if (localStorage.getItem("session_invalid")) {
            localStorage.removeItem("session_invalid")

            UIkit.notification({
                message: this.$t('session.timed.out'),
                status: 'info',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });
        }

        window.addEventListener("dragover", function (e) {
            e.stopPropagation();
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e.stopPropagation();
            e.preventDefault();
        }, false);


        let self = this;
        self.getTerminalConnectorRunning();
        setInterval(function () {
            self.getTerminalConnectorRunning();
        }, 2000);

    },
}

</script>

<style lang="scss">
.remove-transition{
    transition: none!important;
}

.uk-text-dark{
    color: $site-background;
}
.module-disabled-struct {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.module-disabled {
    pointer-events: none !important;
}

.custom-link {
    color: #21295e !important;
}

.footer {
    margin-top: auto;
}

.site-struct {
    max-width: $max-site-width;
    margin: auto;

    &.single {
        max-width: $max-site-width-single;
    }

    &.extended {
        max-width: $max-site-width-extended;
    }

    &.full {
        max-width: 100%;
    }
}

.title-struct {
    background-color: rgba(34, 34, 34, 0.72);
    backdrop-filter: saturate(180%) blur(20px);
    color: #fff;
    font-size: 28px;
}


.uk-label {
    text-transform: initial !important;
    cursor: default !important;
}

.theme-struct {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    user-select: none;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100%;
    background-color: $site-background !important;
    color: $site-color;
    font-size: $site-font-size;
    min-width: 350px;
}

.dark {
    color: $site-background;
}

.cursor-pointer {
    cursor: pointer !important;
}

.content {
    color: $site-color;
    // overflow-y: auto;

    flex-direction: column;
    min-height: 100vh;
    // height: calc(100vh - 72px - 72px - 46px - 80px - 80px - 40px);


}

.menu {
    width: 80px;
    background-color: #fff;
    -webkit-box-shadow: 0 14px 25px #000;
    box-shadow: 0 14px 25px #000;
    color: #000 !important;
    padding-top: 30px !important;
    z-index: 1000;
}

.half-struct {
    max-width: $max-site-width-single;
    margin: auto;
}

.uk-input {
    //border-color: #999 !important;

    &:hover {
        border-color: #1e87f0 !important;
    }
}


.section-distance {
    margin-top: 150px;
}

.blur-fix {
    height: 1px;
}

.blurfix-text {
    height: 22px;
}

.uk-sticky-placeholder {
    height: 0 !important;
}

.uk-label {
    font-size: 16px !important;
    border-radius: $border-radius !important;
    padding: 3px 15px 3px 15px !important;

    &.uk-label-success {
        background-color: $success-color !important;
    }

    &.uk-label-info {
        background-color: $info-color !important;
    }
}

.uk-card-hovered {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16) !important;
}

.Password {
    max-width: initial !important;
}

.uk-table th {
    text-transform: initial !important;
}

table tr td a {
    display: block;
    height: 100%;
    width: 100%;
}

.uk-notification-message {
    border-radius: $border-radius;
}

.uk-nav-header {
    text-transform: initial !important;
}

.uk-label-theme {
    background-color: $theme-background !important;
    color: #fff;
}

.uk-progress::-webkit-progress-value {
    background-color: $success-color !important;
}

.uk-progress::-moz-progress-bar {
    background-color: $success-color !important;
}

.uk-progress::-ms-fill {
    background-color: $success-color !important;
}

.uk-form-success {
    color: $success-color !important;
}

.uk-navbar-dropdown {
    border-radius: $border-radius !important;
}


img::selection {
    color: initial;
    background: none;
}

.cursor-initial {
    cursor: initial !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-notallowed {
    cursor: not-allowed !important;
}

.uk-padding-remove-left {
    padding-left: 0 !important;
}

.uk-padding-remove-right {
    padding-right: 0 !important;
}

.dot {

    margin-top: 8px;

    height: 6px;
    width: 6px;
    background-color: $theme-color-hover;
    border-radius: 50%;

    &.desktop {
        margin-left: -11px;
    }

    &.mobile {
        //  margin-top: 25px;

    }
}

table tr td a {
    padding: 16px 0 16px 0;
}

.highcharts-credits, .highcharts-legend {
    display: none !important;
}

.logo {
    height: 42px;
}

.logo-struct {
    height: 42px;
}

.uk-slideshow-items-view {
    opacity: 0.6;
}

.gallery-background {
    background-color: #092633 !important;
}

.uk-switcher {
    margin-top: 25px;
}

.uk-tab > * > a, .uk-tab > * > span {
    text-transform: initial !important;
    font-size: 16px !important;
    font-weight: 400;
    color: $site-background;
    border-background: transparent;

    &:hover {
        color: $site-background-hover;
        border-bottom: 1px solid $site-background-hover;
    }

    &:focus {
        color: $site-background;
    }
}

.uk-tab > .uk-active > a, .uk-tab > .uk-active > span {
    color: $site-background-hover;
    border-bottom: 1px solid $site-background-hover;
}

.uk-tab > * > span {
    display: flex;
    align-items: center;
    -moz-column-gap: .25em;
    column-gap: 0.25em;
    justify-content: center;
    padding: 5px 10px;
    color: #999;
    border-bottom: 1px solid transparent;
    font-size: .875rem;
    text-transform: uppercase;
    transition: color .1s ease-in-out;
}

.scrollable {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    resize: none;
}

.content-struct {
    min-height: calc(100vh - 60px - 42px - 50px - 42px - 80px - 80px - 36px);
    padding: 40px;

    &.gallery {
        padding: 0 !important;
    }

    &.admin {
        padding-top: 0 !important;
    }
}

@media (max-width: $breakpoint-medium) {
    .content-struct {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: $breakpoint-small) {
    .content-struct {
        padding-left: 10px;
        padding-right: 10px;
    }
}

option {
    font-size: 16px;
}

.uk-input-styled {
    border-radius: $border-radius;
    border-color: $border-color !important;
    height: 50px !important;
    color: $site-background-hover !important;
    width: 100%;
    font-weight: 500 !important;
    font-size: 16px;

    &:hover, &:focus {
        border-color: $theme-background !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $theme-color-hover !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $theme-background !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}

.uk-table-hovered {
    background: #ffd;
}

p {
    margin: 0 0 0 0 !important;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.uk-input, .uk-select {
    &:hover, &:focus {
        border-color: $info-color !important;
    }
}

.uk-tab > .uk-active > a {
    border-color: $info-color-hover !important;
}

*, *:focus, *:hover {
    outline: none;
}

.uk-placeholder-hover {
    transition: border-color 0.25s linear, color 0.25s linear, background-color 0.25s linear !important;

    &:hover, &.hover {
        border-color: $info-color;
    }
}

.dragover {
    border-color: $info-color;
}

.uk-checkbox, .uk-radio {
    &:checked, &:active {
        background-color: $theme-background !important;
        border-color: $theme-background !important;
    }
}

.uk-badge {
    background: $theme-background !important;
}

.uk-link {
    text-decoration: none !important;
}

@media (max-width: 639px) {
    .logo-struct {
        padding: 25px !important;
    }
}

.uk-padding-xsmall {
    padding: 15px !important;
}

.uk-form-theme {
    color: $info-color;
}

.uk-form-warning {
    color: $warning-color;
}

.absence {
    font-size: $site-font-size-large;
}

.uk-margin-xsmall-bottom {
    margin-bottom: 5px !important;
}

.uk-padding-medium {
    padding: 20px !important;
}

@media (min-width: 1200px) {
    .uk-padding-medium {
        padding: 30px !important;
    }
}

.cropper-struct {
    padding: 15px;
    background: #111;
}

.secondary-with-border {
    border: 2px solid #222;
    background: #222;
}

.action-icon {
    color: $site-color;

    &:hover {
        color: $site-color-hover;
    }
}

.uk-padding-slim {
    padding: 10px !important;
}

@media (min-width: 1200px) {
    .uk-padding-slim {
        padding: 20px !important;
    }
}

.uk-margin-xsmall-left {
    margin-left: 5px !important;
}

.empty-logo-struct {
    padding-top: 60px;
}

.slider-struct {
    overflow: hidden;
}

.slim-padding {
    padding: 15px !important;
}

.slim-padding-alt {
    padding: 10px 10px 5px 10px !important;
}

.pwa-prompt-struct {
    // margin-bottom: 30px !important;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.pwa-hint-struct {
    margin-top: 30px !important;
    position: sticky;
    bottom: 0;
    z-index: 1000;
}

.uk-margin-xsmall-right {
    margin-right: 5px;
}

.ql-editor {
    overflow: auto !important;
    max-height: 400px !important;
}

/*
.show-hint-struct {
    margin-bottom: 44px;
}

.show-hint {
    background-color: $danger-color;
    padding: 10px;
}
 */


.simple-keyboard-comments {
    border-radius: 0 !important;
}

.hg-button {
    border-radius: 0 !important;
}


.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".de"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".ch"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".at"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".li"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".nl"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".fi"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".en"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".it"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".fr"] {
    max-width: 60px;
}

.hg-theme-default .hg-button.hg-button-numpad0 {
    max-width: 240.01px;
}

.uk-table-hover tbody tr:hover, .uk-table-hover > tr:hover {
    background: rgba(9, 38, 51, .1) !important;
}

.uk-table-hovered {
    background: rgba(9, 38, 51, .1) !important;
}

.uk-form-info,
.uk-form-info:focus {
    color: $info-color !important;
    border-color: $info-color !important;;
}

.uk-width-xsmall {
    width: 15px;
}

</style>
