<template>
    <span
        :uk-tooltip="
            disabled
                ? 'title:' + $t('x.module.disabled.hint', {x: $t('dTransfer')})
                : ''
        "
        :class="disabled ? 'module-disabled-struct' : ''">
        <span
            :class="
                disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'
            ">
            <Card
                is-dark
                mode="default"
                id="transfer-screensavers"
                class="uk-height-1-1">
                <LabelWithError
                    is-label-large
                    :error="inputError"
                    :label="$t(title)"
                    is-dark />

                <Description>
                    <Translate translation-key="background.message" />
                </Description>

                <div
                    class="uk-grid uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1 uk-grid-match"
                    uk-sortable="handle: .uk-sortable-handle"
                    :id="'sortable-' + title"
                    uk-grid>
                    <div
                        v-for="(screensaver, key) in fixed"
                        :id="'f' + key"
                        :key="'f' + key">
                        <Modal
                            :id="'modal-fscreensaver-' + key"
                            is-without-body
                            is-close-outside>
                            <div class="uk-text-center logo-form-struct">
                                <video
                                    v-if="isVideo(screensaver.id)"
                                    muted
                                    oncontextmenu="return false;"
                                    playsinline
                                    autoplay
                                    loop>
                                    <source
                                        :src="
                                            'https://update.phy-prints.com/swifter/' +
                                            screensaver.id
                                        "
                                        :type="
                                            'video/' +
                                            getVideoType(screensaver.id)
                                        " />
                                </video>
                                <img
                                    v-else
                                    :src="
                                        'https://update.phy-prints.com/swifter/' +
                                        screensaver.id
                                    "
                                    uk-img />
                            </div>
                        </Modal>

                        <div class="uk-height-1-1">
                            <div
                                class="uk-position-relative logo-form-struct uk-text-center unified-height"
                                :id="'fscreensaver-upload-' + key">
                                <video
                                    v-if="isVideo(screensaver.id)"
                                    muted
                                    oncontextmenu="return false;"
                                    playsinline
                                    loop
                                    uk-video>
                                    <source
                                        :src="
                                            'https://update.phy-prints.com/swifter/' +
                                            screensaver.id
                                        "
                                        :type="
                                            'video/' +
                                            getVideoType(screensaver.id)
                                        " />
                                </video>
                                <img
                                    v-else
                                    class="logo-form-item"
                                    :src="
                                        'https://update.phy-prints.com/swifter/' +
                                        screensaver.id
                                    "
                                    uk-img />
                            </div>

                            <div
                                class="uk-card uk-card-body uk-padding-xsmall control secondary-with-border control-struct">
                                <div
                                    class="uk-flex-center uk-flex uk-flex-middle"
                                    uk-grid>
                                    <div>
                                        <ToggleItem
                                            :toggle="
                                                'target: #modal-fscreensaver-' +
                                                key
                                            ">
                                            <Icon
                                                :ratio="1.3"
                                                :uk-tooltip="
                                                    'title:' + $t('show')
                                                "
                                                class="cursor-pointer action-icon"
                                                icon="search" />
                                        </ToggleItem>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-for="(screensaver, key) in screensavers"
                        :id="key"
                        :key="key">
                        <Modal
                            :id="'modal-tscreensaver-' + key"
                            is-without-body
                            is-close-outside>
                            <div class="uk-text-center logo-form-struct">
                                <span v-if="input[key]">
                                    <video
                                        v-if="isVideo(input[key])"
                                        muted
                                        oncontextmenu="return false;"
                                        playsinline
                                        autoplay
                                        loop>
                                        <source
                                            :src="getLocalFileUrl(input[key])"
                                            :type="
                                                'video/' +
                                                getVideoType(screensaver)
                                            " />
                                    </video>
                                    <img
                                        v-else
                                        :src="getLocalFileUrl(input[key])"
                                        uk-img />
                                </span>
                                <span v-else-if="screensaver">
                                    <video
                                        v-if="isVideo(screensaver)"
                                        muted
                                        oncontextmenu="return false;"
                                        playsinline
                                        autoplay
                                        loop>
                                        <source
                                            :src="
                                                apiUrl +
                                                '/transfer-screensavers/' +
                                                screensaver
                                            "
                                            :type="
                                                'video/' +
                                                getVideoType(screensaver)
                                            " />
                                    </video>
                                    <img
                                        v-else-if="screensaver"
                                        :src="
                                            apiUrl +
                                            '/transfer-screensavers/' +
                                            screensaver
                                        "
                                        uk-img />
                                </span>
                            </div>
                        </Modal>

                        <div
                            v-if="input[key] || screensaver"
                            class="uk-height-1-1">
                            <div
                                class="uk-position-relative logo-form-struct uk-text-center unified-height"
                                :id="'tscreensaver-upload-' + key">
                                <div v-if="input[key]">
                                    <video
                                        v-if="isVideo(input[key])"
                                        muted
                                        oncontextmenu="return false;"
                                        playsinline
                                        loop
                                        uk-video>
                                        <source
                                            :src="getLocalFileUrl(input[key])"
                                            :type="
                                                'video/' +
                                                getVideoType(screensaver)
                                            " />
                                    </video>

                                    <img
                                        v-else
                                        class="logo-form-item"
                                        :src="getLocalFileUrl(input[key])"
                                        uk-img />
                                </div>

                                <div v-else-if="screensaver">
                                    <video
                                        v-if="isVideo(screensaver)"
                                        muted
                                        oncontextmenu="return false;"
                                        playsinline
                                        loop
                                        uk-video>
                                        <source
                                            :src="
                                                apiUrl +
                                                '/transfer-screensavers/' +
                                                screensaver
                                            "
                                            :type="
                                                'video/' +
                                                getVideoType(screensaver)
                                            " />
                                    </video>
                                    <img
                                        v-else
                                        class="logo-form-item"
                                        :src="
                                            apiUrl +
                                            '/transfer-screensavers/' +
                                            screensaver
                                        "
                                        uk-img />
                                </div>

                                <div
                                    v-else
                                    class="logo uk-light uk-flex uk-flex-middle uk-flex-center">
                                    {{ $t('no.x', {x: $t(title)}) }}
                                </div>
                            </div>

                            <div
                                class="uk-card uk-card-body uk-padding-xsmall control secondary-with-border control-struct">
                                <div
                                    class="uk-flex-center uk-flex uk-flex-middle"
                                    uk-grid>
                                    <div v-if="false">
                                        <Icon
                                            :ratio="1.3"
                                            :uk-tooltip="'title:' + $t('move')"
                                            class="uk-sortable-handle cursor-pointer action-icon"
                                            icon="table" />
                                    </div>

                                    <div>
                                        <span
                                            v-on:click="
                                                triggerInputDialog(key)
                                            ">
                                            <Icon
                                                :ratio="1.3"
                                                :uk-tooltip="
                                                    'title:' +
                                                    $t(
                                                        input[key] ||
                                                            screensaver
                                                            ? 'replace'
                                                            : 'add'
                                                    )
                                                "
                                                class="cursor-pointer action-icon"
                                                icon="cloud-upload" />
                                        </span>

                                        <input
                                            :id="'tscreensaver-' + key"
                                            :ref="'tscreensaver-' + key"
                                            :accept="
                                                '.' + fileExtensions.join(', .')
                                            "
                                            type="file"
                                            class="uk-hidden"
                                            v-on:click="
                                                $refs[
                                                    'tscreensaver-' + key
                                                ].value = null
                                            "
                                            v-on:change="handleChange(key)" />
                                    </div>

                                    <div v-if="input[key] || screensaver">
                                        <ToggleItem
                                            :toggle="
                                                'target: #modal-tscreensaver-' +
                                                key
                                            ">
                                            <Icon
                                                :ratio="1.3"
                                                :uk-tooltip="
                                                    'title:' + $t('show')
                                                "
                                                class="cursor-pointer action-icon"
                                                icon="search" />
                                        </ToggleItem>
                                    </div>

                                    <div v-if="input[key] || screensaver">
                                        <span v-on:click="handleDelete(key)">
                                            <Icon
                                                :ratio="1.5"
                                                :uk-tooltip="
                                                    'title:' + $t('delete')
                                                "
                                                class="cursor-pointer action-icon"
                                                icon="trash" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="uk-height-1-1" v-else>
                            <div
                                class="uk-card uk-card-body uk-padding-xsmall control secondary-with-border uk-height-1-1">
                                <div
                                    class="uk-flex-center uk-flex uk-flex-middle uk-flex-center uk-height-1-1 empty"
                                    uk-grid>
                                    <div class="screensaver-struct">
                                        <span
                                            v-on:click="
                                                triggerInputDialog(key)
                                            ">
                                            <Icon
                                                :ratio="1.3"
                                                :uk-tooltip="
                                                    'title:' +
                                                    $t(
                                                        input[key] ||
                                                            screensaver
                                                            ? 'replace'
                                                            : 'add'
                                                    )
                                                "
                                                class="cursor-pointer action-icon"
                                                icon="cloud-upload" />
                                        </span>

                                        <input
                                            :id="'tscreensaver-' + key"
                                            :ref="'tscreensaver-' + key"
                                            :accept="
                                                '.' + fileExtensions.join(', .')
                                            "
                                            type="file"
                                            class="uk-hidden"
                                            v-on:click="
                                                $refs[
                                                    'tscreensaver-' + key
                                                ].value = null
                                            "
                                            v-on:change="handleChange(key)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="uk-margin-medium-bottom" />
                <Button
                    :action="actionUpdate"
                    :is-loading="loading"
                    :text="$t('x.save', {x: $t(title)})"
                    has-action
                    is-large
                    is-max
                    mode="primary" />

                <div v-if="error" class="uk-margin-medium-bottom" />
                <ErrorMessage :error="error" />

                <div v-if="success" class="uk-margin-medium-bottom" />
                <SuccessMessage
                    :success="success"
                    :message="$t('x.changes.saved', {x: $t(title)})" />
            </Card>
        </span>
    </span>
</template>

<script>
import SuccessMessage from '@/components/generic/SuccessMessage.vue';
import Button from '@/components/generic/Button.vue';
import Card from '@/components/generic/Card.vue';
import ErrorMessage from '@/components/generic/ErrorMessage.vue';
import axios from 'axios';
import Icon from '@/components/generic/Icon.vue';
import Modal from '@/components/generic/Modal.vue';
import ToggleItem from '@/components/generic/ToggleItem.vue';
import LabelWithError from '@/components/generic/LabelWithError.vue';
import Vue from 'vue';
import UIkit from 'uikit';
import Description from '@/components/Description.vue';
import Translate from '@/components/Translate.vue';

export default {
    name: 'TransferScreensaversForm',
    components: {
        Translate,
        Description,
        LabelWithError,
        ToggleItem,
        Modal,
        Icon,
        ErrorMessage,
        Card,
        Button,
        SuccessMessage,
    },
    props: {
        data: Object,
        fixed: Array,

        allowedExtensions: Array,
        allowedSize: Number,

        disabled: Boolean,
    },
    created() {
        this.screensavers = this.data;
    },
    data() {
        return {
            title: 'background',

            loading: false,
            success: false,
            error: null,

            input: [],
            inputError: null,
            screensavers: [],
            positions: [],
        };
    },
    computed: {
        fileExtensions() {
            const extensions = this.allowedExtensions;

            const lower = extensions.map((element) => {
                return element.toLowerCase();
            });

            return [...new Set(lower)];
        },
    },
    mounted() {
        let self = this;
        let id = 'sortable-' + self.title;

        document.addEventListener('moved', function (e) {
            if (e.target.id === id) {
                self.positions = [];

                let sortable = UIkit.sortable('#' + id);
                sortable.items.forEach(function (item, index) {
                    self.positions.push({
                        id: item.id,
                        index: index,
                    });
                });
            }
        });
    },
    methods: {
        setSort() {
            if (this.positions.length === 0) {
                this.error = null;
                this.loading = false;
                this.success = true;

                return;
            }

            let self = this;

            let parsedPositions = [];

            let objectEntries = Object.entries(this.positions);
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of objectEntries) {
                parsedPositions.push({
                    id: parseInt(value.id),
                    index: parseInt(value.index),
                });
            }

            let http = axios.create();
            http.put(self.apiUrl + '/screensavers-positions', {
                positions: parsedPositions,
            })
                .then(() => {
                    self.error = null;
                    self.loading = false;
                    self.success = true;

                    this.$emit('updated');
                })
                .catch((e) => {
                    self.checkGenericError(e);
                    self.error = e;
                    self.loading = false;
                    self.success = false;
                });
        },
        triggerInputDialog(key) {
            document.getElementById('tscreensaver-' + key).click();
        },
        handleDelete(key) {
            this.success = false;
            Vue.set(this.screensavers, key, null);
            Vue.set(this.input, key, null);
        },
        handleChange(key) {
            let file = this.$refs['tscreensaver-' + key][0].files[0];
            if (!file) {
                return;
            }

            this.handleFileInput(key, file);
        },
        handleFileInput(key, file) {
            let fileExtension = file.name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();
            if (!this.fileExtensions.includes(fileExtension)) {
                this.inputError = this.$t('file.type.error', {
                    name: this.$t(this.title),
                    types: this.getFormattedTypesString(this.fileExtensions),
                });

                return;
            }

            if (file.size > this.allowedSize * 1024 * 1024) {
                this.inputError = this.$t('file.size.error', {
                    name: this.$t(this.title),
                    mb: this.allowedSize,
                });
                return;
            }

            this.inputError = null;
            this.error = null;
            this.success = false;

            Vue.set(this.input, key, file);
        },
        actionUpdate() {
            let objectEntries = Object.entries(this.input);
            let count = 0;

            if (objectEntries.length > 0) {
                this.loading = true;
            } else {
                this.setSort();
            }

            let self = this;
            let timeout = 0;

            for (const [key, value] of objectEntries) {
                setTimeout(function () {
                    if (value) {
                        let formData = new FormData();
                        formData.append('file', value);

                        let http = axios.create();
                        http.put(
                            self.apiUrl + '/transfer-screensavers/' + key,
                            formData
                        )
                            .then(() => {
                                if (count >= objectEntries.length - 1) {
                                    self.setSort();
                                }
                            })
                            .catch((e) => {
                                self.checkGenericError(e);
                                self.error = e;
                                self.loading = false;
                                self.success = false;
                            });
                    } else {
                        let http = axios.create();
                        http.delete(
                            self.apiUrl + '/transfer-screensavers/' + key
                        )
                            .then(() => {
                                if (count >= objectEntries.length - 1) {
                                    self.setSort();
                                }
                            })
                            .catch((e) => {
                                self.checkGenericError(e);
                                self.error = e;
                                self.loading = false;
                                self.success = false;
                            });
                    }

                    count++;
                }, timeout);

                timeout += 300;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.logo-form-item {
    height: 200px;
}

.logo-form-struct {
    padding: 15px;
    background-color: rgba(34, 34, 34, 0.72);
    border: 1px rgba(34, 34, 34, 0.72) solid;
    backdrop-filter: saturate(180%) blur(20px);
    transition: background-color 0.25s linear;
}

.dragover {
    background-color: rgba(34, 34, 34, 0.4);
    border: 1px rgba(34, 34, 34, 0.72) dashed;
}

.empty {
    min-height: 200px;
}

.control-struct {
    height: 63px !important;
}

.unified-height {
    height: calc(100% - 63px - 30px);
}
</style>
