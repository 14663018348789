var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:_vm.disabled ? 'module-disabled-struct' : '',attrs:{"uk-tooltip":_vm.disabled
            ? 'title:' + _vm.$t('x.module.disabled.hint', {x: _vm.$t('gallery')})
            : ''}},[_c('span',{class:_vm.disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'},[_c('LoadingStruct',{attrs:{"is-loading":_vm.loading,"is-animation-disabled":""}},[_c('LabelWithError',{attrs:{"label":_vm.$t('used.storage.size'),"is-bold":""}}),_c('div',{staticClass:"uk-margin-small-bottom"}),_c('Translate',{attrs:{"parameter":{
                    used: _vm.getParsedBytes(_vm.stats.sizeUsageInBytes),
                    total: _vm.getParsedBytes(_vm.stats.sizeLimitInBytes),
                },"translation-key":"x.of.total.x.used"}}),_c('progress',{staticClass:"uk-progress",attrs:{"max":"100"},domProps:{"value":_vm.stats.sizeUsedInPercentage}}),(_vm.isSizeLimitReached)?_c('InfoBox',{attrs:{"icon":"warning","mode":"danger"}},[_c('Translate',{attrs:{"translation-key":"storage.size.limit.reached"}})],1):_vm._e(),_c('div',{staticClass:"uk-margin-medium-top"}),_c('span',{attrs:{"uk-tooltip":'title:' +
                    (_vm.isSizeLimitReached
                        ? _vm.$t('storage.size.limit.reached')
                        : '')}},[_c('Button',{attrs:{"action":_vm.actionNew,"is-disabled":_vm.isSizeLimitReached,"has-action":"","mode":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('x.add', {x: _vm.$t('gallery')}))+" ")])],1),_c('div',{staticClass:"uk-margin-top"}),(!_vm.computedList || _vm.computedList.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('none.x', {x: _vm.$t('galleries')}))+" ")]):_c('div',{staticClass:"uk-overflow-auto"},[_c('table',{staticClass:"uk-table uk-table-middle uk-table-divider uk-table-hover"},[_c('thead',[_c('tr',[_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('thumbnail'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('name'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('reachability'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('article.list'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('active'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('public'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('password'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('share.link'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('cropping'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('folders'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('pictures'))+" ")]),_c('th',{staticClass:"uk-table-shrink"},[_vm._v(" "+_vm._s(_vm.$t('size'))+" ")])])]),_c('tbody',_vm._l((_vm.computedList),function(gallery,key){return _c('tr',{key:'article-' + key,class:'cursor-pointer uk-text-nowrap' +
                                (gallery.id === _vm.hoveredId
                                    ? ' uk-table-hovered'
                                    : '')},[_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[_c('div',{staticClass:"uk-card uk-card-default uk-card-hover cursor-pointer"},[_c('div',{staticClass:"uk-inline uk-transition-toggle uk-width-1-1"},[(gallery.thumbnail)?_c('div',[_c('div',{staticClass:"thumbnail-height uk-background-cover",attrs:{"data-src":_vm.apiUrl +
                                                    '/manage-galleries/' +
                                                    gallery.id +
                                                    '/thumbnail/' +
                                                    gallery.thumbnail +
                                                    '?token=' +
                                                    _vm.getToken,"uk-img":""}})]):_c('div',[_c('div',{staticClass:"thumbnail-height uk-background-cover",attrs:{"data-src":require('../../assets/default_thumbnail.jpg'),"uk-img":""}})])])])]),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[_c('Icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"tag"}}),_vm._v(" "+_vm._s(gallery.name)+" ")],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[(gallery.disabled)?_c('Icon',{staticClass:"uk-form-danger",attrs:{"uk-tooltip":'title:' + _vm.$t('gallery.disabled'),"icon":"ban"}}):(!gallery.articleListID)?_c('Icon',{staticClass:"uk-form-danger",attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('not.available.no.articlelist'),"icon":"close"}}):(
                                        !_vm.isArticleListReachable(
                                            gallery.articleListID
                                        )
                                    )?_c('Icon',{staticClass:"uk-form-danger",attrs:{"uk-tooltip":'title:' +
                                        _vm.$t(
                                            'not.available.articlelist.not.reachable'
                                        ),"icon":"close"}}):(
                                        gallery.hasPassword ||
                                        gallery.shareToken ||
                                        gallery.isPublic
                                    )?_c('div',{staticClass:"uk-grid-small uk-child-width-auto",attrs:{"uk-grid":""}},[(gallery.isPublic)?_c('div',[_c('Icon',{staticClass:"uk-form-success",attrs:{"uk-tooltip":'title:' +
                                                _vm.$t('available.public'),"icon":"eye"}})],1):_vm._e(),(gallery.hasPassword)?_c('div',[_c('Icon',{staticClass:"uk-form-success",attrs:{"uk-tooltip":'title:' +
                                                _vm.$t('available.password'),"icon":"lock"}})],1):_vm._e(),(gallery.shareToken)?_c('div',[_c('Icon',{staticClass:"uk-form-success",attrs:{"uk-tooltip":'title:' +
                                                _vm.$t('available.sharelink'),"icon":"social"}})],1):_vm._e()]):_c('Icon',{attrs:{"uk-tooltip":'title:' + _vm.$t('not.available'),"icon":"close"}})],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[(gallery.articleListID)?_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('with.x', {
                                            x: _vm.$t('article.list'),
                                        }),"icon":"list"}}):_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('without.x', {
                                            x: _vm.$t('article.list'),
                                        }),"icon":"file"}})],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[(gallery.disabled)?_c('Icon',{attrs:{"uk-tooltip":'title:' + _vm.$t('gallery.disabled'),"icon":"ban"}}):(gallery.disableAt)?_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('gallery.will.be.disabled', {
                                            x: _vm.getDaysLeftByDateString(
                                                gallery.disableAt,
                                                true
                                            ),
                                            y: _vm.getFormattedDateTimeByDateString(
                                                gallery.disableAt
                                            ),
                                        }),"icon":"future"}}):_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('gallery.not.disabled'),"icon":"bolt"}})],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[(gallery.isPublic)?_c('Icon',{attrs:{"uk-tooltip":'title:' + _vm.$t('public'),"icon":"eye"}}):_c('Icon',{attrs:{"uk-tooltip":'title:' + _vm.$t('not.public'),"icon":"eye-closed"}})],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[(gallery.hasPassword)?_c('Icon',{attrs:{"uk-tooltip":'title:' + _vm.$t('with.password'),"icon":"lock"}}):_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('without.x', {
                                            x: _vm.$t('password'),
                                        }),"icon":"close"}})],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[(gallery.shareToken)?_c('Icon',{attrs:{"uk-tooltip":'title:' + _vm.$t('with.sharelink'),"icon":"social"}}):_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('without.x', {
                                            x: _vm.$t('share.link'),
                                        }),"icon":"close"}})],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[(!gallery.isCroppingDisabled)?_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('with.x', {
                                            x: _vm.$t('cropping'),
                                        }),"icon":"move"}}):_c('Icon',{attrs:{"uk-tooltip":'title:' +
                                        _vm.$t('without.x', {
                                            x: _vm.$t('cropping'),
                                        }),"icon":"close"}})],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[_c('Icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"folder"}}),_vm._v(" "+_vm._s(gallery.stats.folders)+" ")],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[_c('Icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"image"}}),_vm._v(" "+_vm._s(gallery.stats.picturesTotal)+" ")],1),_c('td',{on:{"click":function($event){return _vm.actionSelect(gallery)}}},[_c('Icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"database"}}),_vm._v(" "+_vm._s(_vm.getParsedBytes( gallery.stats.sizeUsageInBytes ))+" ")],1)])}),0)])]),_c('div',{staticClass:"uk-margin-medium-top"}),_c('span',{attrs:{"uk-tooltip":'title:' +
                    (_vm.isSizeLimitReached
                        ? _vm.$t('storage.size.limit.reached')
                        : '')}},[(_vm.computedList && _vm.computedList.length > 0)?_c('Button',{attrs:{"action":_vm.actionNew,"is-disabled":_vm.isSizeLimitReached,"has-action":"","mode":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('x.add', {x: _vm.$t('gallery')}))+" ")]):_vm._e()],1)],1),_c('Modal',{attrs:{"id":_vm.modalIdDetails,"title":(_vm.selected ? _vm.selected.name + ': ' : '') +
                _vm.$t(_vm.selected ? 'x.change' : 'x.add', {x: _vm.$t('gallery')}),"id-title":"modal-title","is-extended":""}},[(_vm.selected)?_c('InfoBox',{attrs:{"mode":"theme"}},[(_vm.selected.disabled)?_c('Translate',{attrs:{"translation-key":"gallery.disabled"}}):(!_vm.selected.articleListID)?_c('Translate',{attrs:{"translation-key":"gallery.article.list.info"}}):(
                        !_vm.isArticleListReachable(_vm.selected.articleListID)
                    )?_c('Translate',{attrs:{"translation-key":"gallery.article.list.no.active"}}):(
                        _vm.selected.isPublic ||
                        _vm.selected.shareToken ||
                        _vm.selected.hasPassword
                    )?_c('div',[(_vm.selected.isPublic)?_c('div',[_c('Translate',{attrs:{"translation-key":"gallery.public.info"}})],1):_vm._e(),(_vm.selected.hasPassword)?_c('div',[_c('Translate',{attrs:{"translation-key":"gallery.password.info"}})],1):_vm._e(),(_vm.selected.shareToken)?_c('div',[_c('Translate',{attrs:{"translation-key":"gallery.share.link.info"}})],1):_vm._e()]):_c('Translate',{attrs:{"translation-key":"gallery.not.reachable"}})],1):_vm._e(),_c('div',{staticClass:"uk-margin-medium-top"}),_c('ul',{attrs:{"uk-tab":"animation: uk-animation-fade"}},_vm._l((['pictures', 'folders', 'settings']),function(tab){return _c('li',{key:tab},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t(tab)))])])}),0),(_vm.selected)?_c('ul',{staticClass:"uk-switcher uk-margin"},[_c('li',[_c('Translate',{attrs:{"translation-key":"gallery.files.description"}}),_c('div',{staticClass:"uk-margin-top"}),_c('div',{staticClass:"uk-grid uk-grid-small uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-3 uk-light",attrs:{"uk-grid":""}},[_c('div',[_c('span',{on:{"click":function($event){return _vm.actionSelectFolder(null)}}},[_c('LabelSelect',{staticClass:"uk-text-center uk-padding-small",attrs:{"is-selected":_vm.selectedFolder === null}},[(_vm.selected.thumbnail)?_c('img',{staticClass:"favicon-folder",attrs:{"src":_vm.apiUrl +
                                            '/manage-galleries/' +
                                            _vm.selected.id +
                                            '/thumbnail/' +
                                            _vm.selected.thumbnail +
                                            '?token=' +
                                            _vm.getToken,"uk-img":""}}):_c('img',{staticClass:"favicon-folder",attrs:{"src":require('../../assets/default_thumbnail.jpg'),"uk-img":""}}),_c('div',{staticClass:"folder-name",attrs:{"uk-tooltip":'title:' + _vm.selected.name}},[_vm._v(" "+_vm._s(_vm.selected.name)+" "),_c('div',{staticClass:"folder-sub"},[_vm._v(" ("+_vm._s(_vm.$t('folder.root'))+") ")])])])],1)]),_vm._l((_vm.selected.folders),function(folder){return _c('div',{key:folder.name},[_c('span',{on:{"click":function($event){return _vm.actionSelectFolder(folder)}}},[_c('LabelSelect',{staticClass:"uk-text-center uk-padding-small",attrs:{"is-selected":_vm.selectedFolder &&
                                        _vm.selectedFolder.name === folder.name}},[(false)?_c('Icon',{attrs:{"ratio":1.5,"icon":"folder"}}):_vm._e(),(folder.thumbnail)?_c('img',{staticClass:"favicon-folder",attrs:{"src":_vm.apiUrl +
                                            '/manage-galleries/' +
                                            _vm.selected.id +
                                            '/folders/' +
                                            folder.name +
                                            '/thumbnail/' +
                                            folder.thumbnail +
                                            '?token=' +
                                            _vm.getToken,"uk-img":""}}):_c('img',{staticClass:"favicon-folder",attrs:{"src":require('../../assets/default_thumbnail.jpg'),"uk-img":""}}),_c('div',{staticClass:"folder-name",attrs:{"uk-tooltip":'title:' + folder.name}},[_vm._v(" "+_vm._s(folder.name)+" ")])],1)],1)])})],2),(_vm.isSizeLimitReached)?_c('InfoBox',{staticClass:"uk-margin-medium-bottom",attrs:{"icon":"warning","mode":"danger"}},[_c('Translate',{attrs:{"translation-key":"storage.size.limit.reached"}})],1):_c('div',{staticClass:"js-upload uk-placeholder uk-placeholder-hover uk-text-center cursor-pointer",attrs:{"id":"pictures-upload"},on:{"click":function($event){return _vm.triggerInputDialog()}}},[_c('div',[_c('Icon',{staticClass:"upload-icon",attrs:{"ratio":2.2,"icon":"cloud-upload"}})],1),(_vm.isDeviceMobile && _vm.isUploadInProgress)?_c('div',[_c('span',{staticClass:"uk-text-middle"},[_vm._v(_vm._s(_vm.$t('upload.in.progress.hint')))])]):_c('div',[_c('span',{staticClass:"uk-text-middle"},[_vm._v(_vm._s(_vm.$t('x.drop.or.', {x: _vm.$t('pictures')})))]),_c('span',{staticClass:"uk-link uk-text-middle custom-link"},[_vm._v(_vm._s(_vm.$t('select')))])]),_c('div',{staticClass:"uk-hidden",attrs:{"uk-form-custom":""}},[_c('input',{ref:"pictures",attrs:{"id":"pictures","accept":'.' + _vm.fileExtensions.join(', .'),"multiple":"","type":"file"},on:{"change":function($event){return _vm.handleFilesUpload()},"click":function($event){_vm.$refs.pictures.value = null}}})]),(_vm.picturesError)?_c('div',{staticClass:"uk-margin-top"}):_vm._e(),_c('ErrorMessageString',{staticClass:"uk-form-danger",attrs:{"custom-errors":[
                                {
                                    code: 507,
                                    message: _vm.$t(
                                        'storage.size.limit.reached'
                                    ),
                                },
                                {
                                    code: 422,
                                    message:
                                        _vm.$t('files.type.error', {
                                            name: _vm.$t('pictures'),
                                            types: this.getFormattedTypesString(
                                                this.fileExtensions
                                            ),
                                        }) + '.',
                                } ],"error":_vm.picturesError}})],1),(
                            _vm.getUploadPercentage > 0 &&
                            _vm.getUploadPercentage < 100
                        )?_c('progress',{staticClass:"uk-progress uk-animation-fade",attrs:{"max":"100"},domProps:{"value":_vm.getUploadPercentage}}):_vm._e(),(_vm.filesError)?_c('ErrorMessage',{attrs:{"error":_vm.filesError}}):(_vm.filesLoading)?_c('div',{staticClass:"uk-flex-center"},[_c('div',{attrs:{"uk-spinner":""}})]):(!_vm.files || _vm.files.length === 0)?_c('div',{staticClass:"uk-text-center"},[_c('Translate',{attrs:{"parameter":{x: _vm.$t('pictures')},"translation-key":"none.x"}})],1):_c('div',{staticClass:"uk-grid-small uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-2 terminal-content",attrs:{"uk-grid":""}},_vm._l((_vm.files),function(file,key){return _c('div',{key:'gf' + key},[_c('GalleryFile',{attrs:{"folder":_vm.selectedFolder,"gallery-id":_vm.selected.id,"item":file,"item-key":key,"is-thumbnail":_vm.selectedFolder
                                        ? _vm.selectedFolder.thumbnailSet ===
                                          file.name
                                        : _vm.selected.thumbnailSet ===
                                          file.name},on:{"updated":_vm.actionUpdated,"deleted":_vm.actionSoftDeleteFile}})],1)}),0)],1),_c('li',[_c('div',{staticClass:"uk-width-medium"},[_vm._l((_vm.folderFields),function(inputField,key){return _c('span',{key:inputField.name},[_c('TextField',{attrs:{"autocomplete":inputField.autocomplete,"autofocus":inputField.autofocus,"field-key":key,"hasPasswordMeter":inputField.hasPasswordMeter,"icon":inputField.icon,"is-optional":inputField.optional,"label":inputField.label,"name":inputField.name,"on-enter":_vm.actionCreateFolder,"options":inputField.options,"rules":inputField.rules,"steps":inputField.steps,"type":inputField.type,"validate":_vm.triggerFolder},on:{"setValid":_vm.folderSetValid},model:{value:(inputField.value),callback:function ($$v) {_vm.$set(inputField, "value", $$v)},expression:"inputField.value"}}),(key < _vm.folderFields.length - 1)?_c('div',{staticClass:"uk-margin-bottom"}):_vm._e()],1)}),_c('div',{staticClass:"uk-margin-top"}),_c('Button',{attrs:{"action":_vm.actionCreateFolder,"is-loading":_vm.folderLoading,"has-action":"","is-large":"","is-max":"","mode":"theme"}},[_c('Translate',{attrs:{"translation-key":"folder.add"}})],1),_c('div',{staticClass:"uk-margin-medium-top"})],2),_c('div',{staticClass:"uk-grid uk-grid-small uk-child-width-1-6@l uk-child-width-1-5@m uk-child-width-1-4@s uk-child-width-1-3 uk-light",attrs:{"uk-grid":""}},[_c('div',[_c('span',{on:{"click":function($event){return _vm.actionSelectFolder(null)}}},[_c('LabelSelect',{staticClass:"uk-text-center uk-padding-small",attrs:{"is-selected":_vm.selectedFolder === null}},[(_vm.selected.thumbnail)?_c('img',{staticClass:"favicon-folder",attrs:{"src":_vm.apiUrl +
                                            '/manage-galleries/' +
                                            _vm.selected.id +
                                            '/thumbnail/' +
                                            _vm.selected.thumbnail +
                                            '?token=' +
                                            _vm.getToken,"uk-img":""}}):_c('img',{staticClass:"favicon-folder",attrs:{"src":require('../../assets/default_thumbnail.jpg'),"uk-img":""}}),_c('div',{staticClass:"folder-name",attrs:{"uk-tooltip":'title:' + _vm.selected.name}},[_vm._v(" "+_vm._s(_vm.selected.name)+" "),_c('div',{staticClass:"folder-sub"},[_vm._v(" ("+_vm._s(_vm.$t('folder.root'))+") ")])])])],1)]),_vm._l((_vm.selected.folders),function(folder){return _c('div',{key:folder.name},[_c('span',{on:{"click":function($event){return _vm.actionSelectFolder(folder)}}},[_c('LabelSelect',{staticClass:"uk-text-center uk-padding-small",attrs:{"is-selected":_vm.selectedFolder &&
                                        _vm.selectedFolder.name === folder.name}},[(false)?_c('Icon',{attrs:{"ratio":1.5,"icon":"folder"}}):_vm._e(),(folder.thumbnail)?_c('img',{staticClass:"favicon-folder",attrs:{"src":_vm.apiUrl +
                                            '/manage-galleries/' +
                                            _vm.selected.id +
                                            '/folders/' +
                                            folder.name +
                                            '/thumbnail/' +
                                            folder.thumbnail +
                                            '?token=' +
                                            _vm.getToken,"uk-img":""}}):_c('img',{staticClass:"favicon-folder",attrs:{"src":require('../../assets/default_thumbnail.jpg'),"uk-img":""}}),_c('div',{staticClass:"folder-name",attrs:{"uk-tooltip":'title:' + folder.name}},[_vm._v(" "+_vm._s(folder.name)+" ")])],1)],1)])})],2),_c('hr',{staticClass:"uk-hr"}),_c('div',{staticClass:"uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match",attrs:{"uk-grid":""}},[_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_vm._l((_vm.nameFields),function(inputField,key){return _c('span',{key:inputField.name},[_c('TextField',{attrs:{"autocomplete":inputField.autocomplete,"autofocus":inputField.autofocus,"field-key":key,"hasPasswordMeter":inputField.hasPasswordMeter,"icon":inputField.icon,"is-optional":inputField.optional,"label":inputField.label,"name":inputField.name,"on-enter":_vm.actionUpdateName,"options":inputField.options,"rules":inputField.rules,"steps":inputField.steps,"type":inputField.type,"validate":_vm.triggerName,"is-label-large":""},on:{"setValid":_vm.nameSetValid},model:{value:(inputField.value),callback:function ($$v) {_vm.$set(inputField, "value", $$v)},expression:"inputField.value"}}),(key < _vm.nameFields.length - 1)?_c('div',{staticClass:"uk-margin-bottom"}):_vm._e()],1)}),_c('div',{staticClass:"uk-margin-medium-bottom"}),_c('Button',{attrs:{"action":_vm.actionUpdateName,"is-loading":_vm.nameLoading,"text":_vm.$t('x.save', {
                                            x: _vm.$t(
                                                this.selectedFolder
                                                    ? 'folder.name'
                                                    : 'name'
                                            ),
                                        }),"has-action":"","is-large":"","is-max":"","mode":"primary"}}),(_vm.nameError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.nameError}}),(_vm.nameSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {
                                            x: _vm.$t('name'),
                                        }),"success":_vm.nameSuccess}})],2)],1),_c('div',[_c('GalleryThumbnailForm',{attrs:{"id":_vm.selected.id,"allowed-extensions":_vm.thumbnailExtensions,"allowed-size":_vm.thumbnailMaxSizeInBytes,"data":_vm.selectedFolder
                                        ? _vm.selectedFolder.thumbnail
                                        : _vm.selected.thumbnail,"disabled":_vm.disabled,"folder":_vm.selectedFolder},on:{"updated":_vm.actionUpdated}})],1),(_vm.selectedFolder !== null)?_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('delete'),"is-label-large":""}}),_c('Button',{attrs:{"action":_vm.actionDelete,"has-action":"","is-large":"","is-max":"","mode":"danger"}},[_c('Translate',{attrs:{"parameter":{
                                            x: _vm.$t(
                                                this.selectedFolder
                                                    ? 'folder'
                                                    : 'gallery'
                                            ),
                                        },"translation-key":"x.delete"}})],1)],1)],1):_vm._e()])]),_c('li',[_c('div',{staticClass:"uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match",attrs:{"uk-grid":""}},[_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('password'),"is-label-large":""}}),_c('Description',[_c('Translate',{attrs:{"translation-key":"gallery.password.description"}})],1),(_vm.selected.hasPassword)?_c('div',[(!_vm.selected.password)?_c('Translate',{attrs:{"translation-key":"gallery.password.set"}}):_vm._e(),(!_vm.selected.password)?_c('div',{staticClass:"uk-margin-top"}):_vm._e(),(_vm.selected.password)?_c('TextField',{attrs:{"value":_vm.selected.password,"type":"text","icon":"lock","disabled":"","name":"gallery-password","label":"gallery.password","action-function":_vm.codeToClipboard,"action-icon":"copy-clipboard","action-tooltip":_vm.$t('copy.to.clipboard')}}):_vm._e(),(_vm.selected.password)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('Button',{attrs:{"action":_vm.actionDeletePassword,"is-loading":_vm.passwordDeleteLoading,"has-action":"","is-large":"","is-max":"","mode":"danger"}},[_c('Translate',{attrs:{"translation-key":"x.delete","parameter":{
                                                x: _vm.$t('password'),
                                            }}})],1),(_vm.passwordDeleteError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.passwordDeleteError}})],1):_vm._e(),(!_vm.selected.hasPassword)?_c('div',[_c('TextField',{attrs:{"validate":_vm.passwordTrigger,"is-show-password":"","value":_vm.selected.password,"type":"password","on-enter":_vm.actionUpdatePassword,"icon":"lock","name":"gallery-password","label":"gallery.password","rules":"required|min:8","has-password-meter":""},on:{"setValid":_vm.passwordSetValid},model:{value:(_vm.selected.password),callback:function ($$v) {_vm.$set(_vm.selected, "password", $$v)},expression:"selected.password"}}),_c('div',{staticClass:"uk-margin-medium-top"}),_c('Button',{attrs:{"action":_vm.actionUpdatePassword,"is-loading":_vm.passwordLoading,"has-action":"","is-large":"","is-max":"","mode":"primary"}},[_c('Translate',{attrs:{"translation-key":"set.password"}})],1),(_vm.passwordError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"custom-errors":[
                                            {
                                                code: 424,
                                                message: _vm.$t(
                                                    'gallery.password.duplicate.error'
                                                ),
                                            } ],"error":_vm.passwordError}})],1):_vm._e(),(_vm.passwordDeleteSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t(
                                            'gallery.password.deleted.successfully'
                                        ),"success":_vm.passwordDeleteSuccess}}),(_vm.passwordSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {
                                            x: _vm.$t('password'),
                                        }),"success":_vm.passwordSuccess}})],1)],1),_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('share.link'),"is-label-large":""}}),(_vm.selected.shareToken)?_c('div',{staticClass:"uk-grid-collapse uk-grid-match",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('TextField',{attrs:{"action-function":_vm.copyShareLink,"action-tooltip":_vm.$t('copy.to.clipboard'),"value":_vm.shareLink(),"action-icon":"copy-clipboard","icon":"link"}})],1),_c('div',{staticClass:"uk-width-auto"},[_c('span',{attrs:{"uk-tooltip":'title:' +
                                                _vm.$t('generate.qrcode')}},[_c('Button',{attrs:{"action":_vm.generateQRCodeSharelink,"has-action":"","is-large":"","is-max":"","mode":"default"}},[_c('Icon',{attrs:{"icon":"grid"}})],1)],1)]),_c('div',{staticClass:"uk-width-1-1"},[_c('div',{staticClass:"uk-margin-medium-top"}),_c('Button',{attrs:{"action":_vm.actionCreateShareLink,"is-loading":_vm.shareLoading,"has-action":"","is-large":"","is-max":"","mode":"danger"}},[_c('Translate',{attrs:{"translation-key":"share.link.regenerate"}})],1),_c('div',{staticClass:"uk-margin-medium-top"}),_c('Button',{attrs:{"action":_vm.actionDeleteShareLink,"is-loading":_vm.shareLoading,"has-action":"","is-large":"","is-max":"","mode":"danger"}},[_c('Translate',{attrs:{"translation-key":"delete.share.link"}})],1)],1)]):_c('Button',{attrs:{"action":_vm.actionCreateShareLink,"is-loading":_vm.shareLoading,"has-action":"","is-large":"","is-max":"","mode":"theme"}},[_c('Translate',{attrs:{"translation-key":"generate.share.link"}})],1),(_vm.shareError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.shareError}}),(_vm.shareSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {
                                            x: _vm.$t('share.link'),
                                        }),"success":_vm.shareSuccess}})],1)],1),_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('public'),"is-label-large":""}}),_c('SwitchButton',{attrs:{"is-label-hidden":"","label":"public"},on:{"input":function($event){_vm.publicSuccess = false}},model:{value:(_vm.selected.isPublic),callback:function ($$v) {_vm.$set(_vm.selected, "isPublic", $$v)},expression:"selected.isPublic"}}),_c('div',{staticClass:"uk-margin-bottom"}),(_vm.selected.isPublic)?_c('LabelWithError',{attrs:{"label":_vm.$t('gallery.public.link')}}):_vm._e(),(_vm.selected.isPublic)?_c('div',{staticClass:"uk-grid-collapse",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('TextField',{attrs:{"action-function":_vm.copyLink,"action-tooltip":_vm.$t('copy.to.clipboard'),"value":_vm.link(),"action-icon":"copy-clipboard","icon":"link"}})],1),_c('div',{staticClass:"uk-width-auto"},[_c('span',{attrs:{"uk-tooltip":'title:' +
                                                _vm.$t('generate.qrcode')}},[_c('Button',{attrs:{"action":_vm.generateQRCodePublic,"has-action":"","is-large":"","is-max":"","mode":"default"}},[_c('Icon',{attrs:{"icon":"grid"}})],1)],1)])]):_vm._e(),_c('div',{staticClass:"uk-margin-medium-bottom"}),_c('Button',{attrs:{"action":_vm.actionUpdatePublic,"is-loading":_vm.publicLoading,"text":_vm.$t('x.save', {x: _vm.$t('public')}),"has-action":"","is-large":"","is-max":"","mode":"primary"}}),(_vm.publicError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.publicError}}),(_vm.publicSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {
                                            x: _vm.$t('public'),
                                        }),"success":_vm.publicSuccess}})],1)],1),_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('article.list') +
                                        ' (' +
                                        _vm.$t('optional') +
                                        ')',"is-label-large":""}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.articleListID),expression:"selected.articleListID"}],staticClass:"uk-select uk-input-styled",attrs:{"id":"gallery-article-selector"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.selected, "articleListID", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.articleListSuccess = false}]}},[_c('option',{attrs:{"value":""}}),_vm._l((_vm.articleLists),function(articleList){return _c('option',{key:'al' + articleList.id,domProps:{"value":articleList.id}},[_vm._v(" "+_vm._s(articleList.name)+" ")])})],2),_c('div',{staticClass:"uk-margin-medium-bottom"}),_c('Button',{attrs:{"action":_vm.actionUpdateArticleList,"is-loading":_vm.articleListLoading,"text":_vm.$t('x.save', {
                                            x: _vm.$t('article.list'),
                                        }),"has-action":"","is-large":"","is-max":"","mode":"primary"}}),(_vm.articleListError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.articleListError}}),(_vm.articleListSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {
                                            x: _vm.$t('article.list'),
                                        }),"success":_vm.articleListSuccess}})],1)],1),_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('disable'),"is-label-large":""}}),_c('SwitchButton',{attrs:{"id":"gallery-disable"},on:{"input":_vm.disabledHandler},model:{value:(_vm.selected.disabled),callback:function ($$v) {_vm.$set(_vm.selected, "disabled", $$v)},expression:"selected.disabled"}}),_c('div',{staticClass:"uk-margin-bottom"}),_c('SwitchButton',{attrs:{"is-disabled":_vm.selected.disabled,"label":_vm.$t('with.x', {
                                            x: _vm.$t('disable.date'),
                                        })},on:{"input":function($event){_vm.disableSuccess = false}},model:{value:(_vm.withDisableDate),callback:function ($$v) {_vm.withDisableDate=$$v},expression:"withDisableDate"}}),(_vm.withDisableDate)?_c('div',{staticClass:"uk-animation-fade"},[_c('div',{staticClass:"uk-margin-bottom"}),_c('v-date-picker',{attrs:{"is-expanded":"","mode":"datetime","is24hr":"","locale":_vm.settings.language},model:{value:(_vm.selected.disableAt),callback:function ($$v) {_vm.$set(_vm.selected, "disableAt", $$v)},expression:"selected.disableAt"}})],1):_vm._e(),_c('div',{staticClass:"uk-margin-medium-bottom"}),_c('Button',{attrs:{"action":_vm.actionDisable,"is-loading":_vm.disableLoading,"text":_vm.$t('x.save', {
                                            x: _vm.$t('disable'),
                                        }),"has-action":"","is-large":"","is-max":"","mode":"primary"}}),(_vm.disableError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.disableError}}),(_vm.disableSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {
                                            x: _vm.$t('disable'),
                                        }),"success":_vm.disableSuccess}})],1)],1),_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('disable.cropping'),"is-label-large":""}}),_c('SwitchButton',{attrs:{"id":"gallery-disable-cropping"},model:{value:(_vm.selected.isCroppingDisabled),callback:function ($$v) {_vm.$set(_vm.selected, "isCroppingDisabled", $$v)},expression:"selected.isCroppingDisabled"}}),_c('div',{staticClass:"uk-margin-medium-bottom"}),_c('Button',{attrs:{"action":_vm.actionDisableCropping,"is-loading":_vm.disableCroppingLoading,"text":_vm.$t('x.save', {
                                            x: _vm.$t('disable.cropping'),
                                        }),"has-action":"","is-large":"","is-max":"","mode":"primary"}}),(_vm.disableCroppingError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.disableCroppingError}}),(_vm.disableCroppingSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {
                                            x: _vm.$t('disable.cropping'),
                                        }),"success":_vm.disableCroppingSuccess}})],1)],1),_c('div',[_c('Card',{attrs:{"is-dark":"","mode":"default"}},[_c('LabelWithError',{attrs:{"label":_vm.$t('delete'),"is-label-large":""}}),_c('Button',{attrs:{"action":_vm.actionDelete,"has-action":"","is-large":"","is-max":"","mode":"danger"}},[_c('Translate',{attrs:{"parameter":{
                                            x: _vm.$t(
                                                this.selectedFolder
                                                    ? 'folder'
                                                    : 'gallery'
                                            ),
                                        },"translation-key":"x.delete"}})],1)],1)],1)])])]):_vm._e()],1),_c('Modal',{attrs:{"id":_vm.modalIdDelete,"title":(_vm.selectedFolder
                    ? _vm.selectedFolder.name + ': '
                    : _vm.selected
                    ? _vm.selected.name + ': '
                    : '') +
                _vm.$t('x.delete', {
                    x: _vm.$t(this.selectedFolder ? 'folder' : 'gallery'),
                })}},[_c('div',{staticClass:"uk-text-center"},[(_vm.selectedFolder)?_c('Translate',{attrs:{"translation-key":"folder.delete.confirm"}}):_c('Translate',{attrs:{"translation-key":"gallery.delete.confirm"}})],1),_c('div',{staticClass:"uk-flex uk-flex-center uk-margin-medium-top",attrs:{"uk-grid":""}},[_c('div',[_c('Button',{staticClass:"uk-modal-close",attrs:{"text":_vm.$t('cancel'),"is-large":"","is-max":"","is-min-width":"","mode":"default"}})],1),_c('div',[_c('Button',{attrs:{"action":_vm.actionDeleteConfirmed,"is-loading":_vm.deleteLoading,"text":_vm.$t('x.delete', {
                                x: _vm.$t(
                                    this.selectedFolder
                                        ? 'folder'
                                        : 'gallery'
                                ),
                            }),"has-action":"","is-large":"","is-max":"","is-min-width":"","mode":"danger"}})],1)]),(_vm.deleteError)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('ErrorMessage',{attrs:{"error":_vm.deleteError}}),(_vm.deleteSuccess)?_c('div',{staticClass:"uk-margin-medium-bottom"}):_vm._e(),_c('SuccessMessage',{attrs:{"message":_vm.$t('x.changes.saved', {x: _vm.$t('public')}),"success":_vm.deleteSuccess}})],1),_c('Modal',{attrs:{"id":_vm.modalIdCreate,"title":_vm.$t('x.add', {x: _vm.$t('gallery')})}},[_vm._l((_vm.nameFields),function(inputField,key){return _c('span',{key:inputField.name},[_c('TextField',{attrs:{"autocomplete":inputField.autocomplete,"autofocus":inputField.autofocus,"field-key":key,"hasPasswordMeter":inputField.hasPasswordMeter,"icon":inputField.icon,"is-optional":inputField.optional,"label":inputField.label,"label-info":inputField.labelInfo,"name":inputField.name,"on-enter":_vm.actionCreate,"options":inputField.options,"rules":inputField.rules,"steps":inputField.steps,"type":inputField.type,"validate":_vm.triggerName},on:{"setValid":_vm.nameSetValid},model:{value:(inputField.value),callback:function ($$v) {_vm.$set(inputField, "value", $$v)},expression:"inputField.value"}}),(key < _vm.nameFields.length - 1)?_c('div',{staticClass:"uk-margin-bottom"}):_vm._e()],1)}),_c('div',{staticClass:"uk-margin-medium-top"}),_c('Button',{attrs:{"action":_vm.actionCreate,"is-loading":_vm.createLoading,"text":_vm.$t('x.add', {x: _vm.$t('gallery')}),"has-action":"","is-large":"","is-max":"","is-min-width":"","mode":"theme"}})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }